import React from "react";
import { string } from "prop-types";
import Image from "../../components/ImageQuerys/RandomImages";
import Section from "../Base/Section";
import Title from "../../components-v2/Base/Title";

const TopRestaurantsLogos = ({
  sectionTitle,
  className,
  mobileImageName,
  desktopImageName,
}) => {
  return (
    <Section className={`max-w-[1150px] my-20 px-4 md:px-10 ${className}`}>
        {sectionTitle && (
        <Title
          level={4} hTag={4}
          className={`partners__title text-center mb-4 ${className}`}
        >
          {sectionTitle}
        </Title>
      )}
      <Image imageName={mobileImageName} className="lg:hidden" />
      <Image imageName={desktopImageName} className="hidden lg:block" />
    </Section>
  );
};

TopRestaurantsLogos.propTypes = {
  sectionTitle: string,
  className: string,
  mobileImageName: string,
  desktopImageName: string,
};

TopRestaurantsLogos.defaultProps = {
  sectionTitle: "",  
  className: "",
  mobileImageName: "rest-logos-loma-base-camp-vers-mobile.png",
  desktopImageName: "rest-logos-loma-base-camp-vers-desktop.png",
};

export default TopRestaurantsLogos;
